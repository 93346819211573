
import VueDatepickerLocal from 'vue-datepicker-local'
export default {
	name: 'projectManage',
	data() {
		return {
			sex: 'girl',
			newEdit: false,
			list: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			restaurants: [],
			state1: '',
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getProMang()
		this.newEdit = false;
		this.searchList()
	},

	mounted() { },

	components: {
		VueDatepickerLocal
	},
	methods: {
		getProMang() {
			var _this = this;
			_this.$http.post('/API/PLAT/projectListPage', {
				page: _this.currentPage,
				pName: _this.state1
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response.data)
						_this.list = response.data.data.records;
						_this.total = response.data.data.total;
						_this.pageSize = response.data.data.size;
						_this.currentPage = response.data.data.current;
					}
				})
		},
		searchList() {
			var _this = this;
			_this.$http.post('/API/PLAT/findByPName', {
				pName: _this.state1
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.restaurants = response.data.data;
					}
				})
		},
		//搜索按钮
		search() {
			var _this = this;
			_this.$http.post('/API/PLAT/projectListPage', {
				page: _this.currentPage,
				pName: _this.state1
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response.data)
						_this.list = response.data.data.records;
						_this.total = response.data.data.total;
						_this.pageSize = response.data.data.size;
						_this.currentPage = response.data.data.current;
					}
				})
		},
		//搜索框
		querySearch(queryString, cb) {
			var restaurants = this.restaurants;
			var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getProMang()
		},
		//关闭弹框
		closeAlter() {
			this.newEdit = false;
		},
		//点击弹框确定
		submitAlter() {
			this.newEdit = false;
			console.log('确定')
		},
		//点击添加
		newHandel(msg) {
			//				this.newEdit=true;
			this.$router.push({
				name: 'addProject',
				params: {
					id: '0',
					isEidt: '0'
				}
			})
		},
		//编辑
		Edit(msg, id) {
			//				this.newEdit=true;
			this.$router.push({
				name: 'addProject',
				params: {
					id: id,
					isEidt: '1'
				}
			})
		},
		//删除信息
		delListItem() {
			console.log('删除')
		},
		//点击跳转详情
		projectParticulars(id) {
			this.$router.push({
				name: 'projectParticulars',
				params: {
					id: id
				}
			})
			//				console.log(id)
		},
		//模板下载
		templateDownLoad() {
			var _this = this;
			_this.$http.get('/API/PLAT/templateProjectDown', {
				responseType: 'blob'
			}, {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					let filename = "项目模板.xlsx";
					_this.fileDownload(response.data, filename);
				})
		},
		fileDownload(data, fileName) {
			console.log(2)
			let blob = new Blob([data], {
				type: "application/octet-stream"
			});
			let filename = fileName || "filename.xls";
			if (typeof window.navigator.msSaveBlob !== "undefined") {
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var blobURL = window.URL.createObjectURL(blob);
				var tempLink = document.createElement("a");
				tempLink.style.display = "none";
				tempLink.href = blobURL;
				tempLink.setAttribute("download", filename);
				if (typeof tempLink.download === "undefined") {
					tempLink.setAttribute("target", "_blank");
				}
				document.body.appendChild(tempLink);
				tempLink.click();
				document.body.removeChild(tempLink);
				window.URL.revokeObjectURL(blobURL);
			}
		},
	},
}
